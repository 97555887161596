import React from 'react'
import { useLocation } from '@reach/router'
import useContentfulSiteConfiguration from '../../utils/use-contentful-site-configuration'

export interface SEOProps {
  title?: string;
  description?: string;
}

export const SEO: React.FC<React.PropsWithChildren<SEOProps>> = ({ title, description, children }) => {
  const siteConfig = useContentfulSiteConfiguration()
  const { pathname } = useLocation()

  const seo = {
    url: `${siteConfig.siteUrl}${pathname}`,
    title: title || siteConfig.siteTitle,
    description: description || siteConfig.siteTitle,
    ...siteConfig
  }

  return (
    <>
      <meta name="test" content="something" />
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={siteConfig.keywords.join(',')} />
      <meta name="og:title" content={seo.title} />
      <meta name="og:description" content={seo.description} />
      <meta name="og:type" content="website" />
      <meta name="twitter:creator" content={seo.twitter} />
      <script rel="preload" src="https://tools.euroland.com/tools/common/eurolandiframeautoheight/eurolandtoolsintegrationobject.js" />
      <link
        rel="preload"
        as="font"
        href="/fonts/Univ000.woff2"
        type="font/woff2"
      />
      {children}
    </>
  )
}
