import { useStaticQuery, graphql } from 'gatsby'

const sharesPageStaticQuery = graphql`
  query sharesPageQuery {
    contentfulCustomPage(slug: { eq: "shares" }) {
      id
      title
      noResults {
        noResults
      }
      hero {
        ...HeroFragment
      }
    }
  }
`

const useSharesPage = () => {
  const data = useStaticQuery(sharesPageStaticQuery)

  return data.contentfulCustomPage
}

export default useSharesPage
